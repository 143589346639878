
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ClientUserListWidget from "@/components/widgets/client/Client-User-List-Widget.vue";
import NewClientUserModal from "@/components/modals/forms/NewClientUserModal.vue";

export default defineComponent({
  name: "client-user-list",
  props: ["clientid"],
  components: {
    ClientUserListWidget,
    NewClientUserModal,
  },
  setup(props) {
    const store = useStore();
    const clientId = ref<number | string>(Number(props.clientid));
    const currentUser = store.getters.currentUser;

    onMounted(() => {
      setCurrentPageTitle("Client Users");
      setCurrentPageBreadcrumbs("Client", ["Admin"]);
    });

    return {
      clientId,
      currentUser,
    };
  },
});
